export const APPEARANCE = {
  CIRCULAR: 'circular',
  LINEAR: 'linear'
} as const;

export const SIZE = {
  DEFAULT: 'default',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export const BEHAVIOR = {
  INDETERMINATE: 'indeterminate',
  DETERMINATE: 'determinate'
} as const;

export type Appearance = (typeof APPEARANCE)[keyof typeof APPEARANCE];
export type Behavior = (typeof BEHAVIOR)[keyof typeof BEHAVIOR];
export type Size = (typeof SIZE)[keyof typeof SIZE];
