import styled from 'styled-components';

// Sizes set based on veneer's https://github.azc.ext.hp.com/veneer/veneer/blob/83e66a5c45a723bb4bc708433addb4dceada436a/packages/core/src/scripts/progress_indicator/progress_indicator.tsx#L42
const circularSizeMap = {
  default: '72px',
  small: '24px',
  medium: '96px',
  large: '192px'
};

interface ProgressIndicatorProps {
  size: keyof typeof circularSizeMap;
}

export const Container = styled.div<ProgressIndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circular = styled.div<ProgressIndicatorProps>`
  max-width: ${({ size }) => circularSizeMap[size] || circularSizeMap.default};
  max-height: ${({ size }) => circularSizeMap[size] || circularSizeMap.default};
`;

export const Linear = styled.div<ProgressIndicatorProps>`
  max-height: ${({ size }) =>
    size === 'small' ? '4px' : size === 'medium' ? '8px' : '16px'};
`;
