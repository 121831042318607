import React from 'react';
import { CircularAnimation } from './AnimationLoader';
import { ProgressIndicator as VeneerProgressIndicator } from '@veneer/core';
import * as S from './styles';
import {
  APPEARANCE,
  BEHAVIOR,
  SIZE,
  Appearance,
  Behavior,
  Size
} from './types';

interface ProgressIndicatorProps {
  appearance?: Appearance;
  behavior?: Behavior;
  size?: Size;
  [key: string]: any;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  appearance = APPEARANCE.CIRCULAR,
  behavior = BEHAVIOR.INDETERMINATE,
  size = SIZE.DEFAULT,
  ...props
}) => {
  return (
    <S.Container
      data-testid="loader-container"
      appearance={appearance}
      size={size}
      {...props}
    >
      {appearance === APPEARANCE.CIRCULAR &&
      behavior === BEHAVIOR.INDETERMINATE ? (
        <CircularAnimation size={size} />
      ) : (
        <VeneerProgressIndicator appearance={'linear'} />
      )}
    </S.Container>
  );
};

export default ProgressIndicator;
