import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import circularAnimation from '../assets/v1/circular.json';
import linearAnimation from '../assets/v1/linear.json';
import * as S from '../styles';
import { Size } from '../types';

interface AnimationLoaderProps {
  file: object;
}

const AnimationLoader: React.FC<AnimationLoaderProps> = ({ file }) => {
  const container = useRef<HTMLDivElement>(null);
  const animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current!,
      animationData: file,
      rendererSettings: {
        progressiveLoad: true
      }
    });
    return () => {
      animation.current && animation.current.destroy();
    };
  }, [file]);

  return (
    <div
      ref={container}
      data-testid="loader"
    />
  );
};

interface CircularAnimationProps {
  size: Size;
}

export const CircularAnimation: React.FC<CircularAnimationProps> = ({
  size
}) => {
  return (
    <S.Circular size={size}>
      <AnimationLoader file={circularAnimation} />
    </S.Circular>
  );
};

interface LinearAnimationProps {
  size: string;
}

export const LinearAnimation: React.FC<LinearAnimationProps> = ({ size }) => {
  return (
    <S.Linear size={size}>
      <AnimationLoader file={linearAnimation} />
    </S.Linear>
  );
};

export default AnimationLoader;
